export function getImageRatio(imageUrl) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = function() {
            const width = this.width;
            const height = this.height;
            resolve(`${width}/${height}`);
        };
        img.onerror = function() {
            reject(new Error('Failed to load image'));
        };
        img.src = imageUrl;
    });
}
export function getImageHeightRatio(imageUrl) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = function() {
            const width = this.width;
            const height = this.height;
            resolve(`${height}/${width}`);
        };
        img.onerror = function() {
            reject(new Error('Failed to load image'));
        };
        img.src = imageUrl;
    });
}
