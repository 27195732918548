<template>
  <div id="categoryZone">
    <div>
      <img :src="item.imgUrl" width="100%" @click="jumpPage(item.categoryList[0])">
    </div>
    <div class="categoryList">
      <div v-for="(item,index) in item.categoryList" :key="index">
        <img :src="item.imgUrl" :width="index%2===0?'84px':'74px'" @click="jumpPage(item)">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CategoryZone",
  // 组件的props定义,用于子组件接收父组件传值
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {}
  },
  methods: {
    jumpPage(item) {
      localStorage.setItem("reload",true)
      this.$router.push({path: '/search', query: {isJd: "1", topicId: item.topicId, title: item.title,pageIndex:1}})
    }
  }
}
</script>
<style>
#categoryZone {
  width: 94.6%;
  margin: 10px auto;
  position: relative;
}

.categoryList {
  position: absolute;
  bottom: 0;
  z-index: 1;
  background-size: 100%;
  padding: 8px 0;
  display: flex;
  justify-content: space-around;
  width: 95%;
  left: 5px;
  align-items: center;
}
</style>
