<!-- 选择收获地址组件 -->
<template>
	<div>
		<div class="content" @click="choseAddress" v-if="path != 'goodsDetail'">
			<div class="addressBox" style="padding:20px 10px;" v-if="JSON.stringify(addressData) == '{}'">
				<div style="margin-left:10px;color:#666;">请选择地址</div>
				<van-icon name="arrow" size="20" color="#999"></van-icon>
			</div>
			<div class="addressBox" v-else>
				<div class="flex_no" style="width: 96%;">
					<div style="margin-left:10px;width:96%">
						<div class="flex_no">
							<van-tag type="danger" style="font-size:10px;margin-right:8px;"
								v-if="addressData.isType == 1">默认</van-tag>
							<div style="font-size:18px;line-height:20px;">{{ addressData.name }} {{ addressData.tel }}</div>
						</div>

						<div class="addressDetail" style="margin:6px 0;">{{ addressData.address }}</div>
						<div style="font-size:13px;color:#333;line-height:20px;">{{ addressData.prowz }} {{
							addressData.citywz }} {{ addressData.areawz }} {{ addressData.streewz }}</div>
					</div>
				</div>
				<van-icon name="arrow" size="20" color="#999"></van-icon>
			</div>
			<!--新增地址-->
		</div>
		<div v-if="path == 'goodsDetail'">
			<div @click="choseAddress" class="controlsItem" style="border-top:1px solid #f7f7f7;">
				<div class="title">送至</div>
				<div style="width:50%;font-size:14px;color:#666;text-align:right;" class="oneLineText">
					<span>{{ addressData.prowz }} {{ addressData.citywz }} {{ addressData.areawz }} {{ addressData.streewz }}</span>
					<van-icon name="arrow" />
				</div>
			</div>
		</div>
		<van-action-sheet v-model="createAddressState" title="创建地址" @closed="closeCreateAddress">
			<CreateAddress v-if="createAddresslife" @submitAddAddress="submitAddAddress"></CreateAddress>
		</van-action-sheet>
		<!--选择地址-->
		<van-action-sheet v-model="choseAddressState" title="配送至" cancel-text="新增地址" @cancel="createBtn">
			<ChoseAddress :addressList="addressList" @selectAddress="selectAddress"></ChoseAddress>
		</van-action-sheet>
	</div>
</template>
<style scoped>
.addressBox {
	display: flex;
	align-items: center;
	padding: 10px;
	justify-content: space-between;
}

.flex_no {
	display: flex;
	align-items: center;
}

.addressDetail {
	width: 100%;
	font-size: 15px;
	font-weight: 500;
	color: #333333;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
</style>
<script>
import ChoseAddress from '@/components/Address/ChoseAddress';
import CreateAddress from '@/components/Address/CreateAddress';
import { getAddressListApi, getAddressDefaultApi } from '@/api/index'
export default {
	props: {
		path: {
			typeof: String,
			default: ""
		}
	},
	components: {
		ChoseAddress,
		CreateAddress
	},
	data() {
		return {
			addressData: {},
			createAddresslife: false, //组件状态 v-if销毁组件
			createAddressState: false,
			choseAddressState: false,
			addressList: [], //存放地址列表
		}
	},
	methods: {
		createBtn() {
			console.log(1);
			this.createAddressState = true
			this.createAddresslife = true
		},
		getAddressDefaul() { //获取该用户默认地址
			let params = {
				userToken: localStorage.getItem('userToken')
			}
			getAddressDefaultApi(params).then(res => {
				if (res.data != null && res.code == 200) {
					this.addressData = res.data
					// this.$store.commit('updateAddressData', this.addressData)
					localStorage.setItem('addressData', JSON.stringify(this.addressData))
				}
			})
			// console.log(data);
		},
		async getAddressList() {
			let { data } = await getAddressListApi()
			console.log(data);
			this.addressList = data
		},
		choseAddress() {
			//调接口 获取地址列表
			if (this.addressList.length == 0) { //如果该用户地址列表为空
				//------弹创建地址弹窗
				this.createAddressState = true
				this.createAddresslife = true
			} else {
				//------弹选择地址弹窗
				this.choseAddressState = true
			}
		},

		selectAddress(index) {
			this.$emit('selectAddress', this.addressList[index].id)
			this.addressData = this.addressList[index]
			// this.$store.commit('updateAddressData', this.addressData)
			localStorage.setItem('selectAddressData', JSON.stringify(this.addressData))
			this.choseAddressState = false
		},

		//关闭创建地址弹窗 需要将弹窗内组件'销毁'以清空数据
		closeCreateAddress() {
			this.createAddresslife = false
		},
		//新增地址组件--提交事件
		submitAddAddress(data) {
			this.addressData = data
			localStorage.setItem('addressData', JSON.stringify(data))
			localStorage.setItem('selectAddressData', JSON.stringify(data))
			this.createAddressState = false
			setTimeout(() => {
				this.createAddresslife = false
				this.getAddressList()
			}, 500);
		},
	},
	created() {
		if (!localStorage.getItem('selectAddressData')) {
			this.getAddressDefaul()
		} else {
			this.addressData = JSON.parse(localStorage.getItem('selectAddressData'))
		}
		this.getAddressList()
	}
}
</script>