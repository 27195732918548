<!-- 商品区组件模板内容 -->
<template>
  <div class="content">
    <van-swipe :autoplay="3000">
      <van-swipe-item v-for="(i, index) in item.arr" :default="i" :key="index">
        <img style="display:block;border-radios:10px;aspect-ratio: 750/421" width="100%"
             v-if="i.src"
             @click="jumpPage(i.src)"
             :src="i.url" alt="" srcset="">
        <img v-else style="display:block;border-radios:10px;aspect-ratio: 750/421" width="100%"
             @click="$router.push({ path: '/search', query: { categoryId:i.categoryId, activeName:i.activeName, categoryLevel: i.categoryLevel,noCategory: i.noCategory } })"
             :src="i.url" alt="" srcset="">

      </van-swipe-item>
    </van-swipe>
  </div>
</template>
<script>
export default {
  name: 'HeaderBannerSwiper',
  // 组件的props定义,用于子组件接收父组件传值
  props: {
    item: {
      type: Object,
    }
  },
  // 组件的data属性
  data() {
    return {
    };
  },
  // 组件的方法
  methods: {
    jumpPage(url){
      location.href=url;
    }
  },
  activated() {

  }
};
</script>

<!-- 组件的样式 -->
<style scoped>
.content {
  width: 100%;
  margin: 0 auto;
}
</style>

