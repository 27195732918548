<template>
  <div>

  </div>
</template>
<script>
export default {
  name: "InviteCode",
  data() {
    return {
    }
  },
  created() {
    location.href=`weixin://dl/business/?appid=${this.$route.query.appId}&path=pages/bind/bind&query=code%3D`+this.$route.query.code
  }
}
</script>
