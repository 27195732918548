<!-- 组件模板内容 轮播图-->
<template>
	<div class="prefectureCard">
		<div class="prefectureCardItem" v-for="(item, index) in prefectureData" :key="index">
			<LoadImg :item="item" :index="index"></LoadImg>
			<div class="prefectureGoodsBox">
				<div class="prefetureGoodsItem" v-for="(it, i) in item.list" :key="i" @click="toDetail(it.goodsId, it.isJd, it.relationType, it.relationId)">
					<img :src="it.picUrl" alt="">
					<div class="prefectureGoodsTitle">{{ it.title }}</div>
					<div style="color:#999;font-size:13px;text-decoration: line-through;">{{ it.price | decimal }}{{
							$store.state.unit }}</div>
					<div style="color:rgb(200, 6, 6);font-weight:600;">{{ it.shopSalePrice | decimal }}{{ $store.state.unit }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
.prefectureCardItem {
	width: 94%;
	margin: 12px auto;
}

.prefectureCardImage {
	width: 100%;
	border-radius: 14px 14px 0px 0px;
	background-position: center;
	background-size: 100% 100%;
	box-sizing: border-box;
	padding: 0 15px;
}

.more_btn {
	width: 70px;
	height: 22px;
	background: #FFFFFF;
	border-radius: 20px;
	font-size: 12px;
	text-align: center;
	line-height: 22px;
}

.prefectureGoodsBox {
	display: flex;
	width: 100%;
	overflow-x: auto;
	overflow-y: hidden;
	background-color: #fff;
	padding: 10px;
	box-sizing: border-box;
}

.prefectureGoodsBox::-webkit-scrollbar {
	display: none;
}

.prefetureGoodsItem {
	width: 33% !important;
	margin-right: 10px;
	flex-shrink: 0;
	font-size: 15px;
}

.prefetureGoodsItem img {
	width: 100%;
	aspect-ratio: 1 / 1;
}

.prefectureGoodsTitle {
	width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	font-size: 14px;
	color: #666;
	margin: 6px 0;
}
</style>
<script>
import { getGoodsGroupByTopicApi } from '@/api/index'
import LoadImg from "@/components/HomeComponents/components/PrefectureCard/loadImg.vue";
export default {
	name: 'prefectureCard',
  components: {
    LoadImg
  },
	// 组件的props定义,用于子组件接收父组件传值
	props: {
		item: {
			type: Object,
		}
	},
	// 组件的data属性
	data() {
		return {
			prefectureData: []
		}
	},
	// 组件的方法
	methods: {
		async getGoodsGroupByTopic() {
			let { data } = await getGoodsGroupByTopicApi({
				wxShopId: localStorage.getItem('shopId')
			})
			this.prefectureData = data;
		},
		toDetail(id, isJd, ruleType, relationId) {
			this.$router.push({
				path: '/GoodsDetail',
				query: { id, isJd, ruleType, relationId ,shopId:localStorage.getItem("shopId")}
			})
		},
	},
	// 在created钩子中执行的操作
	mounted() {

	},
	deactivated() {

	},
	created() {
		this.getGoodsGroupByTopic()
	}
};
</script>

<!-- 组件的样式 -->
<style scoped></style>
