<!-- 搜索框 组件模板内容 -->
<template>
	<div>
		<div class="search_wrap" :style="'background:' + bgColor + ';'">
			<div class="input_wrap">
				<van-icon name="search" size="18" />
				<input type="text" :placeholder="placeholder" :style="'background:' + bgColor + ';'" v-model="searchValue"
					ref="search" @keydown="searchBtn2">
			</div>
			<div class="search_btn" @click="searchBtn" >搜索</div>
		</div>
	</div>
</template>

<script>
import { Dialog } from 'vant'
export default {
	name: "SearchComponents",
	// 组件的props定义,用于子组件接收父组件传值
	props: {
		placeholder: {
			type: String,
			default: '请输入搜索内容'
		},
		bgColor: {
			type: String,
			default: '#fff'
		},
	},
	// 组件的data属性
	data() {
		return {
			searchValue: ''
		};
	},
	// 组件的方法
	methods: {
		searchBtn2(e) {
			if (e.keyCode == 13) {  //键盘回车的编码是13
				this.searchBtn();
			}
		},
		searchBtn() { //跳转搜索页面
			// if (this.searchValue == '') {
			// 	Dialog.confirm({
			// 		title: '提示',
			// 		message: '请输入搜索内容',
			// 	}).then(() => {
			// 	}).catch(() => { });
			// 	return
			// }
			// this.$router.go(-1)
			this.$router.push({
				path: '/search',
				query: { title: this.searchValue }
			})
			if (this.searchValue != '') {
				let arr = JSON.parse(localStorage.getItem('searchHistory')) || []
				let index = arr.findIndex(item => {
					return item == this.searchValue
				})
				if (index > -1) {
					arr.splice(index, 1)
				}
				arr.unshift(this.searchValue)
				localStorage.setItem('searchHistory', JSON.stringify(arr))
			}
		},
	},
	// 在created钩子中执行的操作
	created() {
		this.$nextTick(() => {
			this.$refs.search.focus();
		})
	},
};
</script>
<style scoped>
.search_wrap {
	width: 94.6%;
	aspect-ratio: 10/1;
	background: #fff;
	display: flex;
	align-items: center;
	padding: 4px 10px;
	padding-right: 0;
	border-radius: 20px;
	box-sizing: border-box;
	margin: 0 auto;
}

.input_wrap {
	display: flex;
	align-items: center;
	width: 80%;
	height: 100%;
}

.search_wrap input {
	width: 100%;
	height: 100%;
	border: 0;
	margin-left: 8px;
	color: #666;
	font-size: 14px;
}

.search_btn {
	width: 20%;
	height: 28px;
	line-height: 28px;
	text-align: center;
	font-size: 14px;
}
</style>
<!-- 组件的样式 -->
