<!-- 商品区组件模板内容 -->
<template>
	<div>
		<van-swipe :autoplay="3000">
			<van-swipe-item v-for="(item, index) in topicList" :default="index" :key="index">
				<img style="margin: 10px auto;display:block;border-radios:10px;" width="94.6%" height="100px"
					:src="item.bannerUrl" alt="" srcset="" @click="$router.push({
						path: '/topicDetail',
						query: { topicId: item.id, title: item.title }
					})">
			</van-swipe-item>
		</van-swipe>
	</div>
</template>
<script>
import { getShopTopicListApi } from "@/api/topicApi.js"
export default {
	name: 'BannerArea',
	props: {

	},
	// 组件的data属性
	data() {
		return {
			topicList: []
		};
	},
	// 组件的方法
	methods: {
		async getShopTopicList() {
      let param = {
        wxShopId: localStorage.getItem("shopId")
      }
			let { data } = await getShopTopicListApi(param)
			this.topicList = data
		}
	},
	created() {
		this.getShopTopicList()
	},
};
</script>

  <!-- 组件的样式 -->
<style scoped></style>
