import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		unit: '元',
		addressData:{},
		isEndTime:false,
		shopTitle:""
	},
	getters: {
	},
	mutations: {
		updateIsEndTime(state, data) {
			state.isEndTime = data
		},
		updateUnit(state, data) {
			state.unit = data
		},
		updateAddressData(state, data) {
			state.addressData = data
		},
		updateShopTitle(state, data) {
			document.title=data;
			state.shopTitle = data
		}
	},
	actions: {
	},
	modules: {
	}
})
