<template>
  <div>

  </div>
</template>
<script>
export default {
  name: "BaiJiuJiBindUser",
  data() {
    return {
    }
  },
  created() {
    document.title="唐玖集"
    location.href=`weixin://dl/business/?appid=wxe3f986f79799f5d7&path=pages/index/index&query=bindUserId%3D`+this.$route.query.code
  }
}
</script>
