import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import GoodsDetail from '../views/GoodsDetail.vue'
import Search from '../views/Search.vue'
import User from '../views/User.vue'
import Shopcar from '../views/Shopcar.vue'
import TopicDetail from '../views/topicDetail.vue'
import Captcha from "@/views/Captcha.vue";
import InviteCode from "@/views/InviteCode.vue";
import BaiJiuJiBindUser from "@/views/BaiJiuJiBindUser.vue";
// const originaPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originaPush.call(this, location).catch(err => err)
// }

Vue.use(VueRouter)


const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView,
		meta: {
			index: 0,
			keepAlive: true,//是否缓存
			showTabbar: true //是否显示tabbar
		}
	},
	{
		path: '/viewHome',
		name: 'ViewHome',
		component: HomeView,
		meta: {
			keepAlive: false,//是否缓存
			showTabbar: false //是否显示tabbar
		}
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('../views/Login.vue')
	},
	{
		path: '/goodsDetail/:id?',
		name: 'GoodsDetail',
		component: GoodsDetail
	},
	{
		path: '/search',
		name: 'Search',
		component: Search,
		meta: {
			keepAlive: true,//是否缓存
		}
	},
	{
		path: '/shopcar',
		name: 'Shopcar',
		component: Shopcar,
		meta: {
			index: 1,
			showTabbar: true
		}
	},
	{
		path: '/user',
		name: 'User',
		component: User,
		meta: {
			index: 2,
			showTabbar: true
		}
	},
	{
		path: '/captcha',
		name: 'Captcha',
		component: Captcha,
	},{
		path: '/baiJiuJiBindUser',
		name: 'BaiJiuJiBindUser',
		component: BaiJiuJiBindUser,
	},
	{
		path: '/invite',
		name: 'invite',
		component: InviteCode,
	},
	{

		path: '/addressList',
		name: 'AddressList',
		component: () => import('../views/Address/AddressList.vue')
	},
	{
		path: '/editAddress',
		name: 'EditAddress',
		component: () => import('../views/Address/EditAddress.vue')
	},
	{
		path: '/exchangePage',
		name: 'ExchangePage',
		component: () => import('../views/Exchange/ExchangePaeg.vue')
	},
	{
		path: '/orderDetail',
		name: 'OrderDetail',
		component: () => import('../views/OrderDetail.vue')
	},
	{
		path: '/orderRecord',
		name: 'OrderRecord',
		component: () => import('../views/Record/OrderRecord.vue')
	}, {
		path: '/exchangeRecord',
		name: 'ExchangeRecord',
		component: () => import('../views/Record/ExchangeRecord.vue')
	},
	{
		path: '/beforeSearch',
		name: 'BeforeSearch',
		component: () => import('../views/BeforeSearch.vue')
	},
	{
		path: '/paySuccess',
		name: 'PaySuccess',
		component: () => import('../views/paySuccess.vue')
	}, {
		path: '/logistics',
		name: 'Logistics',
		component: () => import('../views/Logistics.vue')
	},{
		path: '/topicDetail',
		name: 'TopicDetail',
		component: TopicDetail,
		meta: {
			keepAlive: true,//是否缓存
		}
	},
]



const router = new VueRouter({
	mode: "history",
	routes
})

export default router
