import service from "@/utils/request"

// 分页查询首页商品
// export function findGoodsApi(params) {
//     return service({
//         url: '/indexWxLMH5Two/getCommodityListByTopicId.action',
//         method: 'get',
//         params
//     })
// }
//获取店铺信息
export function getShopTypeApi(params) {
	return service({
		url: '/indexWxZLD/getMyShopByH5',
		method: 'post',
		data: params
	})
}
//获取二级分类数据
export function getCateDataApi(params) {
	return service({
		url: '/indexWxZLD/getCategory',
		method: 'post',
		data: params
	})
}
//商品列表查询
export function getGoodsWithWxshopidApi(params) {
	return service({
		url: '/indexWxZLD/getChooseGoods',
		method: 'post',
		data: params
	})
}
//活动商品列表查询
export function getActiveGoodsApi(params) {
	return service({
		url: '/indexWxZLD/activityThemeGoods',
		method: 'post',
		data: params, isShow: true
	})
}
export function getGoodsGroupByTopicApi(params) {
	return service({
		url: '/indexWxZLD/getChooseGoodsGroupByTopic',
		method: 'get',
		params, isShow: true
	})
}


//商品详情查询
export function getGoodsDetailApi(params) {
	return service({
		url: '/indexWxZLD/getGoodsDetails',
		method: 'post',
		data: params, isShow: true
	})
}
//商品配送信息
export function getGoodsformatApi(params) {
	return service({
		url: '/indexWxZLD/deliveryInformation',
		method: 'post',
		data: params
	})
}
//活动专区展示
export function getShopTopicListApi(params) {
	return service({
		url: '/indexWxZLDh5/getShopTopicList',
		method: 'get',
		params
	})
}
//订单查询
export function getMyOrderApi(params) {
	return service({
		url: '/indexWxZLD/getMyOrderSjH5',
		method: 'post',
		data: params
	})
}
//物流信息查询
export function getlogisticsApi(params) {
	return service({
		url: '/WxShopH5/logisticsInformation',
		method: 'get',
		params
	})
}


//购物车
//获取购物车商品
export function getShopCarDataApi(params) {
	return service({
		url: '/indexWxZLD/getMyCartByH5',
		method: 'post',
		data: params, isShow: true
	})
}
//添加购物车
export function addShopcarApi(params) {
	return service({
		url: '/indexWxZLD/addCartByH5',
		method: 'post',
		data: params, isShow: true
	})
}
//修改购物车商品数量
export function upDateGoodsNumApi(params) {
	return service({
		url: '/indexWxZLD/updateCartNumByH5',
		method: 'post',
		data: params
	})
}
//删除购物车商品
export function removeGoodsApi(params) {
	return service({
		url: '/indexWxZLD/deletCart',
		method: 'get',
		params
	})
}
//删除购物车商品
export function getFreightApi(params) {
	return service({
		url: '/indexWxZLD/getFreight',
		method: 'post',
		data: params
	})
}
//获取openid
export function getOpenIdApi(params) {
	return service({
		url: '/indexWxZLDh5/getOpenId',
		method: 'post',
		data: params
	})
}
//单个下单
export function addOrderApi(params) {
	return service({
		url: '/indexWxZLD/addOrder',
		method: 'post',
		data: params
	})
}
//购物车下单
export function addOrderByCartApi(params) {
	return service({
		url: '/indexWxZLD/addOrderByCartByH5',
		method: 'post',
		data: params
	})
}
export function checkCartCanBuy(param){
	return service({
		url: '/indexWxZLD/checkCartCanBuy',
		method: 'post',
		data: param
	})
}





//地址
//获取默认地址
export function getAddressDefaultApi(params) {
	return service({
		url: '/indexWxZLDh5/getAddressDefault',
		method: 'get',
		params
	})
}
//获取地址列表
export function getAddressListApi(params) {
	return service({
		url: '/indexWxZLDh5/getMyAddressList',
		method: 'post',
		data: params
	})
}
//获取省市区数据
export function getProCityAreaApi(params) {
	return service({
		url: '/indexWxZLD/findJdregion',
		method: 'get',
		params
	})
}
//新增地址信息
export function addAddressApi(params) {
	return service({
		url: '/indexWxZLDh5/addAddress',
		method: 'post',
		data: params, isShow: true
	})
}
//编辑地址信息
export function editAddressApi(params) {
	return service({
		url: '/indexWxZLD/updateAddress',
		method: 'post',
		data: params
	})
}
//删除地址信息
export function removeAddressApi(params) {
	return service({
		url: '/indexWxZLD/deletAddress',
		method: 'post',
		data: params
	})
}



//用户
//获取用户信息
export function getUserInfoApi(params) {
	return service({
		url: '/indexWxZLDh5/getMyBaseInfo',
		method: 'get',
		params
	})
}
//修改用户信息
export function editUserInfoApi(params) {
	return service({
		url: '/indexWxZLDh5/updateMyBaseInfo',
		method: 'post',
		data: params, isShow: true
	})
}






//登录
//获取验证码
export function sendCodeApi(params) {
	return service({
		url: '/indexWxZLDh5/sendCode',
		method: 'post',
		data: params, isShow: true
	})
}
//登录接口
export function loginApi(params) {
	return service({
		url: '/indexWxZLD/loginByH5',
		method: 'post',
		data: params, isShow: true
	})
}


//兑换积分
export function exchangeByScoreCodeApi(params) {
	return service({
		url: '/indexWxZLD/exchangeByScoreCodeByH5',
		method: 'post',
		data: params, isShow: true
	})
}
//积分明细
export function getExchangeRecordApi(params) {
	return service({
		url: '/indexWxZLD/getMyJpScoreByH5',
		method: 'post',
		data: params, isShow: true
	})
}
