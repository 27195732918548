<!-- 瓷片区 组件模板内容 -->
<template>
	<div>
		<div class="content_box content_box_1" v-if="hidenJdGoodsList && hidenSjGoodsList && jdGoodsList.length != 0">
			<div class="preference_box activeBox"> <!--优选专区-->
				<div class="activeTitle" @click="jumpPage(2,item)">
					<p style="font-size:13px;font-weight:500">{{item.left && item.left.title || '优选专区'}}<van-icon name="arrow" /></p>
					<p style="color:#bd9a8e;font-size:12px;">{{item.left && item.left.subTitle || '精选优质好物'}}</p>
				</div>

<!--        v-if="item.left && !item.left.hidden"-->
				<template v-if="!item.left || !item.left.hidden">
          <div class="first_goods" @click="toDetail(jdGoodsList[0])" v-if="jdGoodsList.length>0">
            <img :src="jdGoodsList[0].picUrl" alt="">
            <div style="margin-left: 5px;">
              <div class="goods_name">{{ jdGoodsList[0].title }}</div>
              <div style="font-size: 12px;margin-top: 4px;">{{ jdGoodsList[0].shopSalePrice | decimal}} {{
                  jdGoodsList[0].shopUnit }}</div>
            </div>
          </div>
          <div class="second_goods" v-if="jdGoodsList.length>1">
            <div class="second_item" @click="toDetail(jdGoodsList[1])">
              <img :src="jdGoodsList[1].picUrl" alt="">
              <div style="font-size: 12px;">{{ jdGoodsList[1].shopSalePrice | decimal}} {{
                  jdGoodsList[1].shopUnit
                }}</div>
            </div>
            <div class="second_item" @click="toDetail(jdGoodsList[2])" v-if="jdGoodsList.length>2">
              <img :src="jdGoodsList[2].picUrl" alt="">
              <div style="font-size: 12px;">{{ jdGoodsList[2].shopSalePrice | decimal}} {{ jdGoodsList[2].shopUnit }}</div>
            </div>
          </div>
        </template>
        <template v-else>
          <van-empty description="敬请期待" style="padding: 0" image="search"/>
        </template>
			</div>
			<div class="selfSellGoodsBox activeBox" :style="hidenJdGoodsList ? `` : `width:100%;`" v-if="hidenSjGoodsList">
				<div class="activeTitle" :style="hidenJdGoodsList ? `` : `aspect-ratio:360/1;`"
					@click="jumpPage(1,item)"
					style="background-image: url('https://yig-game.oss-cn-hangzhou.aliyuncs.com/BAT/game_icon/selfSell_title.png');">
					<p style="font-size:13px;font-weight:500">{{item.right && item.right.title || '自营专区'}}<van-icon name="arrow" /></p>
					<p style="color:#bd9a8e;font-size:12px;">{{item.right && item.right.subTitle || '正品保障 京东物流'}}</p>
				</div>
<!--        v-if="!item.right && !item.right.hidden"-->
				<template v-if="!item.right || !item.right.hidden">
          <van-swipe class="my-swipe" :autoplay="5000" indicator-color="white">
            <van-swipe-item v-for="(item, index) in sjGoodsList.length / (hidenJdGoodsList ? 2 : 4)" :key="index">
              <div class="first_goods" v-for="(it, i) in (hidenJdGoodsList ? 2 : 4)" :key="i"
                   @click="toDetail(sjGoodsList[i + (index * (hidenJdGoodsList ? 2 : 4))])">
                <template v-if="sjGoodsList[i + (index * (hidenJdGoodsList ? 2 : 4))].hidden != false">
                  <img :src="sjGoodsList[i + (index * (hidenJdGoodsList ? 2 : 4))].picUrl" alt="">
                  <div style="margin-left: 5px;">
                    <div class="goods_name">{{ sjGoodsList[i + (index * (hidenJdGoodsList ? 2 : 4))].title
                      }}
                    </div>
                    <div style="font-size: 12px;margin-top: 4px;">
                      {{ sjGoodsList[i + (index * (hidenJdGoodsList ? 2 : 4))].shopSalePrice | decimal}}{{
                        sjGoodsList[i + (index * (hidenJdGoodsList))].shopUnit }}</div>
                  </div>
                </template>

              </div>
            </van-swipe-item>
          </van-swipe>
        </template>
        <template v-else>
          <van-empty description="敬请期待" style="padding: 0" image="search"/>
        </template>

			</div><!--自营专区-->
		</div>

		<!-- 只存在优选专区 -->
		<div style="box-sizing: border-box;width: 94.6%;margin: 10px auto;background:#fff;"
			v-if="hidenJdGoodsList && !hidenSjGoodsList">
			<div class="activeTitle" @click="jumpPage(2,item)">
				<p style="font-size:13px;font-weight:500;">{{item.left && item.left.title || '优选专区'}}<van-icon name="arrow" /></p>
				<p style="color:#bd9a8e;font-size:12px;">{{item.left && item.left.subTitle || '精选优质好物'}}</p>
			</div>
			<div style="display: flex;align-items:center;width:100%;flex-wrap:wrap;">
				<div class="goodsItem" @click="toDetail(item)" v-for="(item, index) in jdGoodsList" :key="index">
					<img :src="item.picUrl" alt="">
					<div style="width:60%;margin-left:10px;">
						<div class="goods_name">{{ item.title }}</div>
						<div style="font-size: 14px;margin-top: 6px;">{{ item.shopSalePrice | decimal}} {{ item.shopUnit }}</div>
					</div>
				</div>
			</div>
		</div>
		<!--只存在自营专区-->
		<div style="box-sizing: border-box;width: 94.6%;margin: 10px auto;background:#fff;"
			v-if="!hidenJdGoodsList && hidenSjGoodsList && sjGoodsList.length">
			<div class="activeTitle" @click="jumpPage(1,item)"
				style="background-image: url('https://yig-game.oss-cn-hangzhou.aliyuncs.com/BAT/game_icon/selfSell_title.png');">
				<p style="font-size:13px;font-weight:500">{{item.right && item.right.title || '自营专区'}}<van-icon name="arrow" /></p>
				<p style="color:#bd9a8e;font-size:12px;">{{item.right && item.right.subTitle || '正品保障 京东物流'}}</p>
			</div>
			<van-swipe class="my-swipe" :autoplay="5000" indicator-color="white">
				<van-swipe-item style="display:flex;flex-wrap:wrap;"
					v-for="(item, index) in sjGoodsList.length / (hidenJdGoodsList ? 2 : 4)" :key="index">
					<div style="width: 50%;display:flex;padding:2px;box-sizing: border-box;margin:6px 0;"
						v-for="(it, i) in (hidenJdGoodsList ? 2 :4)" :key="i"
						@click="toDetail(sjGoodsList[i + (index * (hidenJdGoodsList ? 2 : 4))])">
						<template v-if="sjGoodsList[i + (index * (hidenJdGoodsList ? 2 : 4))].hidden != false">
							<img style="width:40%;" :src="sjGoodsList[i + (index * (hidenJdGoodsList ? 2 : 4))].picUrl"
								alt="">
							<div style="margin-left: 5px;">
								<div class="goods_name" style="width: 100%;">{{ sjGoodsList[i + (index * (hidenJdGoodsList ?
									2 :
									4))].title }}
								</div>
								<div style="font-size: 12px;margin-top: 4px;">
									{{ sjGoodsList[i + (index * (hidenJdGoodsList ? 2 : 4))].shopSalePrice | decimal}}{{
										sjGoodsList[i + (index * (hidenJdGoodsList))].shopUnit }}</div>
							</div>
						</template>
					</div>
				</van-swipe-item>
			</van-swipe>
		</div>
	</div>
</template>
<style scoped>
.goodsItem {
	width: 50%;
	box-sizing: border-box;
	padding: 10px 5px;
	display: flex;
	align-items: self-start;
}

.goodsItem img {
	width: 40%;
	border-radius: 5px;
}

/*京东和自营都存在*/
.content_box {
	box-sizing: border-box;
	width: 94.6%;
	margin: 10px auto;
	border-radius: 10px;
	display: flex;
	/* align-items: center; */
	justify-content: space-between;
}

.content_box_1 .activeBox {
	width: 48.5%;
	border-radius: 6px;
	aspect-ratio: 331/450;
	background: #fff;
}

.activeTitle {
	width: 100%;
	aspect-ratio: 10/1;
	background-image: url('https://yig-game.oss-cn-hangzhou.aliyuncs.com/BAT/game_icon/preference_titile.png');
	background-position: center;
	background-size: 100% 100%;
	box-sizing: border-box;
	padding: 5px;
}

.content_box_1 .activeTitle {
	width: 100%;
	aspect-ratio: 3.6/1;
	background-image: url('https://yig-game.oss-cn-hangzhou.aliyuncs.com/BAT/game_icon/preference_titile.png');
	background-position: center;
	background-size: 100% 100%;
	box-sizing: border-box;
	padding: 5px;
}



.content_box_1 .first_goods {
	display: flex;
	align-items: flex-start;
	padding: 10px;
	overflow: hidden;
}

.content_box_1 .first_goods img {
	width: 40%;
	aspect-ratio: 1/1;
	border-radius: 5px;
}

.goods_name {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	text-overflow: ellipsis;
	overflow: hidden;
	font-size: 13px;
	color: #333;
}

.content_box_1 .second_goods {
	padding: 0 10px 10px 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 5px;
	text-align: center;
}

.content_box_1 .second_goods .second_item {
	width: 44%;
	padding: 1px;
}

.content_box_1 .second_goods .second_item img {
	width: 100%;
	aspect-ratio: 1/1;
	border-radius: 5px;
}

.content_box_1 .van-swipe-item {
	aspect-ratio: 1/1;
	padding: 0px 1px;
	box-sizing: border-box;
}
</style>
<script>
import {getActiveGoodsApi, getGoodsWithWxshopidApi} from "@/api/index"
export default {
	name: 'PorcelainTileArea',
	// 组件的props定义,用于子组件接收父组件传值
	props: {
		item: {
			type: Object,
		}
	},
	// 组件的data属性
	data() {
		return {
			preference_data: [1], //优选商品列表
			selfSell_data: [1], //自营商品列表
			jdGoodsList: [],
			sjGoodsList: [],
			hidenJdGoodsList: false,
			hidenSjGoodsList: false,
		};
	},
	// 组件的方法
	methods: {
    jumpPage(isJd,item){
      if (isJd===1){
        if (item.right){
          if (item.right.hidden){
            return;
          }
          if (item.right.topicId){
            this.$router.push({ path: '/search', query: { isJd ,topicId:item.right.topicId,title:item.right.title} })
            return;
          }
        }
      }else{
        if (item.left){
          if (item.left.hidden){
            return;
          }
          if (item.left.topicId){
            this.$router.push({ path: '/search', query: { isJd ,topicId:item.left.topicId,title:item.right.title} })
            return;
          }
        }
      }
      this.$router.push({ path: '/search', query: { isJd } })
    },
		toDetail(item) {
			this.$router.push({
				path: '/GoodsDetail',
				query: { id: item.goodsId, isJd: item.isJd, ruleType: item.relationType, relationId: item.relationId,shopId:localStorage.getItem("shopId") }
			})
		},
		async getGoodsWithWxshopid() {
      if (this.item.left && this.item.left.topicId){
        let {data}= await getActiveGoodsApi({
          wxShopId: localStorage.getItem("shopId"),
          distributorId: localStorage.getItem('distributorId'),
          topicId: this.item.left.topicId,
          isJd: '',
          pageIndex: 1,
          pageSize: 10,
          title: ''
        })
        if (data.list.length < 1) {
          this.hidenJdGoodsList = false;
        } else {
          this.jdGoodsList = data.list.splice(0,4);
          this.hidenJdGoodsList = true;
        }
      }else{
        let { data } = await getGoodsWithWxshopidApi({
          wxShopId: localStorage.getItem("shopId"),
          distributorId: localStorage.getItem('distributorId'),
          searchWhere: 1,
          isJd: 2,
          pageIndex: 1,
          pageSize: 10
        })

        if (data.list.length < 1) {
          this.hidenJdGoodsList = false;
        } else {
          this.jdGoodsList = data.list.splice(0,4);
          this.hidenJdGoodsList = true;
        }
      }
      if (this.item.right && this.item.right.topicId){
        let {data:res}= await getActiveGoodsApi({
          wxShopId: localStorage.getItem("shopId"),
          distributorId: localStorage.getItem('distributorId'),
          topicId: this.item.right.topicId,
          isJd: '',
          pageIndex: 1,
          pageSize: 10,
          title: ''
        })
        if (res.list.length < 1) {
          this.hidenSjGoodsList = false;
        } else {
          this.hidenSjGoodsList = true;
          this.sjGoodsList = Array.from(res.list)
          if (this.hidenJdGoodsList) { // /2
            if (this.sjGoodsList.length % 2 != 0) {
              this.sjGoodsList.push({ hidden: false })
            }
          } else {  // /4
            let num = 4 - (this.sjGoodsList.length % 4)
            for (let i = 0; i < num; i++) {
              this.sjGoodsList.push({ hidden: false })
            }
          }
        }
      }else{
        let { data: res } = await getGoodsWithWxshopidApi({
          wxShopId: localStorage.getItem("shopId"),
          distributorId: localStorage.getItem('distributorId'),
          searchWhere: 1,
          isJd: 1,
          pageIndex: 1,
          pageSize: 16
        })
        if (res.list.length < 1) {
          this.hidenSjGoodsList = false;
        } else {
          this.hidenSjGoodsList = true;
          this.sjGoodsList = Array.from(res.list)
          if (this.hidenJdGoodsList) { // /2
            if (this.sjGoodsList.length % 2 != 0) {
              this.sjGoodsList.push({ hidden: false })
            }
          } else {  // /4
            let num = 4 - (this.sjGoodsList.length % 4)
            for (let i = 0; i < num; i++) {
              this.sjGoodsList.push({ hidden: false })
            }
          }
        }
      }

		}
	},
	// 在created钩子中执行的操作
	created() {
		this.$nextTick(() => {
			this.getGoodsWithWxshopid()
		})
	}
};
</script>
<!-- "https://yig-game.oss-cn-hangzhou.aliyuncs.com/BAT/game_icon/selfSell_title.png" -->
<!-- "https://yig-game.oss-cn-hangzhou.aliyuncs.com/BAT/game_icon/preference_titile.png" -->
<!-- 组件的样式 -->
