<template>
	<div class="search">
		<div class="searchBox" @click="$router.push({ name: 'BeforeSearch', path: '/beforeSearch' })">
			<input class="searchInput" type="text" placeholder="搜想要的商品" id="keyWord" />
			<div class="searchBtn">搜索</div>
		</div>
		<div>
			<van-icon name="cart-o" size="24" style="margin-right:10px;" @click="$router.push('/shopCar')"/>
			<van-icon name="service-o" size="24"/>
		</div>
	</div>
</template>

<script>
export default {
	name: 'headerSearch',
	components: {

	},
	props: {

	},
	data() {
		return {

		}
	},
	methods: {

	}
}

</script>
<style scoped lang="less">
.search {
	width: 94.6%;
	margin: 10px auto;
	display: flex;
	align-items: center;
	justify-content: space-between;

	&>div {
		display: flex;
		align-items: center;
	}

	.searchBox {
		width: 100%;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-radius: 12px;
		background: #fff;
		padding-left: 12px;
		padding-right: 4px;
		box-sizing: border-box;

		.searchInput {
			outline: 0;
			border: 0;
			height: 30px;
			line-height: 30px;
			font-size: 14px;
		}

		.searchBtn {
			width: 57px;
			height: 25px;
			background: #57C3FF;
			border-radius: 12px;
			font-size: 14px;
			font-family: PingFang SC, PingFang SC;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 25px;
			text-align: center;
		}
	}
}
</style>