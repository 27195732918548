<!-- 组件模板内容 -->
<template>
	<div class="wrap">
		<div class="content">
			<div class="cate_item" :style="`width:${(item.num == 10 ? 20 : item.num == 5 ? 20 : 25)}%;`"
				v-for="(it, index) in cate_data" :key="index" :item="it"
				@click="it.categoryId == -1 ? $router.push('/search') : $router.push({ path: '/search', query: { categoryId: it.categoryId, activeName: index, categoryLevel: it.categoryLevel } })">
				<img class="cate_img" :src="'https://sjzcsaas.oss-cn-hangzhou.aliyuncs.com/sj/category/' + it.icon" alt="">
				<div style="margin-top:6px;" class="cateTitle">{{it.categoryId==-1?it.name: it.fullName }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getCateDataApi } from '@/api/index'
export default {
	name: 'JinGang',
	// 组件的props定义,用于子组件接收父组件传值
	props: {
		item: {
			type: Object,
		}
	},
	// 组件的data属性
	data() {
		return {
			cate_data: []
		};
	},
	// 组件的方法
	methods: {
		async getCateData() {
			localStorage.setItem('categoryLevel',this.item.categoryLevel)
      let param = {
        shopId: localStorage.getItem('shopId'),
        categoryLevel: this.item.categoryLevel,
        distributorId: localStorage.getItem('distributorId')
      }
			let { data } = await getCateDataApi(param)
			this.cate_data = data
			if (data.length > (this.item.num * 1)) {
				this.cate_data = data.splice(0, (this.item.num * 1) - 1)
				this.cate_data.push({
					categoryId: -1,
					name: '更多',
					icon: 'gd.png'
				})
			}
		}
	},
	// 在created钩子中执行的操作
	created() {
		this.getCateData()
	}
};
</script>

<!-- 组件的样式 -->
<style scoped>
.wrap {
	margin-top: -1px;
}

.content {
	display: flex;
	align-items: center;
	background: #FFFFFF;
	margin: 0 auto;
	width: 94.6%;
	border-radius: 10px;
	flex-wrap: wrap;
	box-sizing: border-box;
	padding-bottom: 15px;
}

.cate_item {
	display: flex;
	align-items: center;
	flex-direction: column;
	font-size: 14px;
	margin-top: 15px;
}

.cate_img {
	width: 45px;
	height: 45px;
	border-radius: 10px;
}

.cateTitle {
	width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	text-align: center;
	font-size: 13px;
}
</style>
