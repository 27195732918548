<!-- 商品区组件模板内容 -->
<template>
  <div class="content">
    <img :src="item.url" width="100%"  alt=""/>
  </div>
</template>
<script>
export default {
  name: 'ImgArea',
  // 组件的props定义,用于子组件接收父组件传值
  props: {
    item: {
      type: Object,
    }
  },
  // 组件的data属性
  data() {
    return {
    };
  },
  // 组件的方法
  methods: {

  },
  activated() {

  }
};
</script>

<!-- 组件的样式 -->
<style scoped>

</style>

