<template>
	<!--横向滚动分类组件内容-->
	<div class="cate_content">
		<div class="cate_item" :style="`${font_style}${index == 0 ? select_style : ''}`" v-for="(item, index) in cate_data"
			:key="index">{{ item }}</div>
	</div>
</template>

<style scoped>
.cate_content {
	display: flex;
	align-items: center;
	width: 94.6%;
	overflow-x: scroll;
	margin: 0 auto;
	padding: 0;
}

.cate_content::-webkit-scrollbar {
	display: none;
}

.cate_item {
	padding: 2px 5px;
	border-radius: 15px;
	white-space: nowrap;
	margin-right: 15px;
}
</style>

<script>
export default {
	name: 'CateComponents',
	props: {
		cate_data: {
			type: Array,
			default: []
		},
		font_style: {
			type: String,
			default: ''
		},
		select_style: {
			type: String,
			default: ''
		}
	},
	data() {
		return {

		}
	},
	methods: {

	},
	created() {
	}
}
</script>

