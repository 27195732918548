<!-- 组件模板内容 轮播图-->
<template>
	<div class="BandGoodsArea">
		<template v-if="item.type == 1">
			<div class="goodsWrap" v-for="(items, index) in prefectureData" :key="index"
				:style="{ 'background-image': 'url(' + areaSet[index % 3].backgroundImg + ')' }">
				<div :style="{ color: areaSet[index % 3].fontColor }">
					<div style="font-size: 18px;">{{ items.title }}</div>
					<div style="font-size: 13px;opacity: 0.86;">{{ items.subTitle }}</div>
				</div>
				<div class="flex_sb">
					<div class="goodsItem" v-for="(it, i) in items.list.slice(0, 3)" :key="i"
						@click="toDetail(it.goodsId, it.isJd, it.relationType, it.relationId)">
						<img style="width:80px;height:80px;" :src="it.picUrl" alt="">
						<div
							style="width:94.6%;font-size: 12px;color: #333333;margin:10px 0;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
							{{ it.title }}</div>
						<div style="font-size: 13px;color: #333333;">{{ it.price | decimal }}{{ $store.state.unit }}
						</div>
						<div class="buyBtn">立即购买</div>
					</div>
				</div>
				<div style="position: absolute;top: 0;right: 0;">
          <div @click="$router.push({ path: '/topicDetail', query: { topicId: items.topicId, title: items.title } })"
               class="moreBtn" :style="{ background: areaSet[index % 3].btnColor }">查看更多</div>
          <div style="position: relative;top: 0;right: 13px;z-index: 1" v-if="areaSet[index % 3].btnBgImg">
            <img :src="areaSet[index % 3].btnBgImg" height="24px" style="opacity: .4">
          </div>
        </div>
			</div>
		</template>
		<template v-if="item.type == 2">
			<div class="goodsWrap_type2" v-for="(items, index) in  prefectureData " :key="index">
				<div class="titleBox"
					:style="{ 'color': areaSet[index % 3].fontColor, 'background-image': 'url(' + areaSet[index % 3].backgroundImg + ')', 'aspect-ratio': '705/130' }">
					<div style="font-size: 12px;">{{ items.title }}</div>
					<div style="font-size: 13px;opacity: 0.86;margin-top:5px;">{{ items.subTitle }}</div>
				</div>
				<div class="flex_sb">
					<div class="goodsItem" style="background-color:#fff;" v-for="( it, i ) in  items.list.slice(0, 3) "
						:key="i" @click="toDetail(it.goodsId, it.isJd, it.relationType, it.relationId)">
						<img style="width:80px;height:80px;" :src="it.picUrl" alt="">
						<div
							style="width:94.6%;font-size: 13px;color: #333333;margin:10px 0;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
							{{ it.title }}</div>
						<div style="font-size: 13px;color: #333333;">{{ it.price | decimal }}{{ $store.state.unit }}
						</div>
						<div class="buyBtn">立即购买</div>
					</div>
				</div>
			</div>
		</template>
		<template v-if="item.type == 3">
			<div class="goodsWrap_type3" v-for="( items, index ) in  prefectureData " :key="index">
				<div class="titleBox"
					:style="{ 'color': areaSet[index % 3].fontColor, 'background-image': 'url(' + areaSet[index % 3].backgroundImg + ')' }">
					<div style="font-size: 18px;">{{ items.title }}</div>
					<div style="font-size: 13px;opacity: 0.86;">{{ items.subTitle }}</div>
					<div class="joinBtn flex_no"
						@click="$router.push({ path: '/topicDetail', query: { topicId: items.topicId, title: items.title } })"
						:style="{ 'color': areaSet[index % 3].fontColor, 'border-color': areaSet[index % 3].fontColor }">
						<van-icon name="minus" style="margin-right:-5px" />
						<van-icon name="arrow" style="margin-left:-5px;" />
					</div>
				</div>
				<div class="flex_sb goodsList">
					<div class="goodsItem" style="background-color:transparent;padding: 8px 5px;aspect-ratio: 216/300;"
						v-for="( it, i ) in  items.list.slice(0, 3) " :key="i"
						@click="toDetail(it.goodsId, it.isJd, it.relationType, it.relationId)">
						<img style="width:88px;height:88px;" :src="it.picUrl" alt="">
						<div
							style="width:94.6%;font-size: 12px;color: #333333;margin:10px 0;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
							{{ it.title }}</div>
						<div style="font-size: 14px;font-weight:600;color: #E15B5B;">{{ it.price | decimal }}{{
			$store.state.unit }}
						</div>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>
<style scoped>
.titleBox {
	width: 100%;
	aspect-ratio: 705/200;
	box-sizing: border-box;
	padding: 10px;
	padding-bottom: 0;
}

.goodsWrap {
	width: 94.6%;
	aspect-ratio: 710/410;
	margin: 15px auto;
	position: relative;
	padding: 24px 12px;
	box-sizing: border-box;
  padding-top: 14px;
}

.goodsWrap_type2 {
	width: 94.6%;
	margin: 15px auto;
	box-sizing: border-box;
}

.goodsWrap_type3 {
	width: 94.6%;
	margin: 15px auto;
	box-sizing: border-box;
}

.goodsList {
	width: 100%;
	box-sizing: border-box;
	background: #fff;
	margin-top: -14px;
	position: relative;
	border-radius: 15px 0 15px 15px;
}

.moreBtn {
  z-index: 10;
	width: 80px;
	height: 24px;
	position: absolute;
	right: 14px;
	top: 0px;
	background: #1964EE;
	border-radius: 47px 0px 47px 0px;
	border: 1px solid #F9DCAC;
	font-family: PingFang SC;
	font-weight: 500;
	font-size: 12px;
	color: #FFFFFF;
	line-height: 22px;
	text-align: center;
}

.goodsItem {
	width: 32%;
	aspect-ratio: 216/317;
	background: #F1F9FF;
	margin-top: 10px;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 13px 0;
	box-sizing: border-box;
}

.buyBtn {
	position: absolute;
	width: 60%;
	bottom: -10px;
	left: 50%;
	margin-left: -30%;
	aspect-ratio: 129/40;
	background: #2F2725;
	font-size: 12px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}

.joinBtn {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	border: 1px solid #000;
	justify-content: center;
	margin-top: 6px;
}
</style>
<script>
import { getGoodsGroupByTopicApi } from '@/api/index'
import { RouterLink } from 'vue-router';
export default {
	name: 'BandGoodsArea',
	// 组件的props定义,用于子组件接收父组件传值
	props: {
		item: {
			type: Object,
		}
	},
	// 组件的data属性
	data() {
		return {
			prefectureData: [],
			areaSet: [{
				backgroundImg: "https://sjzcsaas.oss-cn-hangzhou.aliyuncs.com/sj/shopTemplate/pfBank/blue_back.png",
				fontColor: "#0D60FA",
				btnColor: "#1964EE",
			}, {
				backgroundImg: "https://sjzcsaas.oss-cn-hangzhou.aliyuncs.com/sj/shopTemplate/pfBank/pink_back.png",
				fontColor: "#CF5336",
				btnColor: "#da6262",
			}, {
				backgroundImg: "https://sjzcsaas.oss-cn-hangzhou.aliyuncs.com/sj/shopTemplate/pfBank/orange_back.png",
				fontColor: "#A15330",
				btnColor: "#C85F30",
			}]
		}
	},
	// 组件的方法
	methods: {
		async getGoodsGroupByTopic() {
			let { data } = await getGoodsGroupByTopicApi({
				wxShopId: localStorage.getItem('shopId')
			})
			this.prefectureData = data;
			console.log(this.prefectureData);
		},
		toDetail(id, isJd, ruleType, relationId) {
			this.$router.push({
				path: '/GoodsDetail',
				query: { id, isJd, ruleType, relationId,shopId:localStorage.getItem("shopId") }
			})
		},
	},
	// 在created钩子中执行的操作
	mounted() {

	},
	deactivated() {

	},
	created() {
		if (this.item.areaSet){
      this.areaSet=this.item.areaSet;
    }
		this.getGoodsGroupByTopic()
	}
};
</script>

<!-- 组件的样式 -->
<style scoped></style>
