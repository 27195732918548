import service from "@/utils/request"

// 活动专区展示
export function getShopTopicListApi(params) {
    return service({
        url: '/indexWxZLDh5/getShopTopicList',
        method: 'get',
        params
    })
}
