<!-- 商品列表"瀑布流"组件内容 -->
<template>
	<div class="content">

		<div class="goods_list" v-if="goods_data_left.length > 0">
			<div class="goods_col"> <!--left-->
				<div class="goods_item" v-for="(item, index) in goods_data_left" :key="index"
					@click="toDetail(item.goodsId, item.isJd, item.relationType, item.relationId)">
					<img class="goods_image" :src="item.picUrl" alt=""> <!--商品图片-->
					<div class="goods_name" style="margin-top:5px;">{{ item.title }}</div> <!--商品名称-->
					<div style="display:flex;justify-content: space-between;margin-top:5px;">
						<div class="pay_price"> <span style="font-size:16px;">{{ item.shopSalePrice | decimal }}</span>
							{{
			$store.state.unit }}</div>
						<!--商品售价-->
						<div class="old_price">{{ item.price | decimal }}元</div> <!--商品原价-->
					</div>
				</div>
			</div>
			<div class="goods_col"> <!--right-->
				<div class="goods_item" v-for="(item, index) in goods_data_right" :key="index"
					@click="toDetail(item.goodsId, item.isJd, item.relationType, item.relationId)">
					<img class="goods_image" :src="item.picUrl" alt="">
					<div class="goods_name" style="margin-top:5px;">{{ item.title }}</div>
					<div style="display:flex;justify-content: space-between;margin-top:5px;">
						<div class="pay_price"> <span style="font-size:16px;">{{ item.shopSalePrice | decimal }}</span>
							{{
			$store.state.unit }}</div>
						<div class="old_price">{{ item.price | decimal }}元</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="goods_data_left.length < 1" class="toast_title"> <!--当商品数据为空时-->
			<img src="https://img.yzcdn.cn/vant/custom-empty-image.png" alt="">
			<div>很抱歉，商品不存在</div>
			<div style="margin-top:6px;">您可更换关键词搜索或稍后再试</div>
		</div>
	</div>
</template>

<style scoped>
.goods_list {
	display: flex;
	justify-content: space-between;
	width: 94.6%;
	margin: 0 auto;
}

.goods_col {
	width: 49%;
}

.goods_item {
	width: 100%;
	background: #fff;
	padding: 10px;
	box-sizing: border-box;
	margin-top: 10px;
	border-radius: 10px;
}

.goods_image {
	width: 100%;
	height: auto;
	border-radius: 4px;
}

.goods_name {
	width: 100%;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	text-overflow: ellipsis;
	overflow: hidden;
	font-size: 14px;
	color: #333;
}

.pay_price {
	font-size: 14px;
	color: rgb(200, 6, 6);
}

.old_price {
	font-size: 14px;
	color: #999;
	text-decoration: line-through;
}

.toast_title {
	text-align: center;
	font-size: 14px;
	color: #999;
}

.toast_title img {
	width: 30%;
	padding: 1px;
	aspect-ratio: 1/1;
	margin: 20px 0;
}
</style>
<script>
export default {
	name: "GoodsListWaterfall",
	// 组件的props定义,用于子组件接收父组件传值
	props: {
		goods_data_left: {
			typeof: Array,
			default: []
		},
		goods_data_right: {
			typeof: Array,
			default: []
		},
		isLoading: {
			typeof: Boolean,
			default: true
		},
	},
	// 组件的data属性
	data() {
		return {
			// goods_data_right: [],
			// goods_data_left: []
		};
	},
	// 组件的方法
	methods: {

		toDetail(id, isJd, ruleType, relationId) {
			this.$router.push({
				path: '/GoodsDetail',
				query: { id, isJd, ruleType, relationId ,shopId:localStorage.getItem("shopId")}
			})
		},
	},
	// 在created钩子中执行的操作
	created() {
	},
	Updated() {

	}
};
</script>

<!-- 组件的样式 -->
<style scoped></style>
