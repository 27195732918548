<!-- 商品区组件模板内容 -->
<template>
	<div class="content" :style="item.width?'width:'+item.width:''">
		<img :src="item.url" alt="">
		<van-notice-bar v-if=" +new Date() < +new Date(item.endTime)" left-icon="volume-o" :text="item.msg" />
	</div>
</template>
<script>
export default {
	name: 'headerBanner',
	// 组件的props定义,用于子组件接收父组件传值
	props: {
		item: {
			type: Object,
		}
	},
	// 组件的data属性
	data() {
		return {
			shopId: localStorage.getItem('shopId')
		};
	},
	// 组件的方法
	methods: {

	},
	activated() {

	}
};
</script>

<!-- 组件的样式 -->
<style scoped>
.content {
	width: 94.6%;
	margin: 0 auto;
}

.content img {
	width: 100%;
	height: auto;
}

/*.content {
	width: 94.6%;
	aspect-ratio: 350/92.7;
	padding: 1px;
	margin: 0 auto;
}

.content img{
	width: 100%;
	aspect-ratio: 350/92.7;
	padding: 1px;
}*/
</style>
