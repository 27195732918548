<template>
  <div>

  </div>
</template>
<script>
export default {
  name: "Captcha",
  data() {
    return {
    }
  },
  created() {
    location.href=`weixin://dl/business/?appid=${this.$route.query.appId}&path=pages/captcha/captcha&query=code%3D`+this.$route.query.code
  }
}
</script>
